import { Component, OnInit } from '@angular/core';
import { Pet } from '../../models/pets';
import { ActivatedRoute, Router } from '@angular/router';
import { PetService } from '../../services/pets.service';
import { CartService } from '../../services/cart.service';
import { Title } from '@angular/platform-browser';
import { Item } from '../../models/item';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  pet: Pet = new Pet();
  pets: Pet[] = [];
  constructor(private route: ActivatedRoute,
    private router: Router,
    private petService: PetService,
    private cartService: CartService,
    private titleService: Title) {

  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      let id: string = params["id"];
      this.pet = this.petService.find(id)
      this.pets = this.petService.findRandomAndExclude(3, id);
      if (!this.pet) {
        this.router.navigate(["/"]);
      }
    })
    this.titleService.setTitle("Adopt " + this.pet.name + " online")
  }

  addToCart() {
      let item: Item = new Item();
      item.pet = this.pet;
      this.cartService.add(item);
      this.router.navigate(["/checkout"])
  }

  printPrice(price : number)
  {
    var price_parts = price.toString().split(".");
    price_parts[0] = price_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return price_parts.join(".");
  }

}
