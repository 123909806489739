import { NgtUniversalModule } from '@ng-toolkit/universal';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { FooterComponent } from './components/footer/footer.component';
import { ScriptService } from './services/script.service';
import { ProductsComponent } from './components/products/products.component';
import { ProductComponent } from './components/product/product.component';
import { ContactComponent } from './components/contact/contact.component';
import { AboutComponent } from './components/about/about.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { PetService } from './services/pets.service';
import { TransferHttpModule, TransferHttpService } from '@gorniv/ngx-universal';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { CartService } from './services/cart.service';
import { ApiService } from './services/api.service';
import { SEOService } from './services/seo.service';
import { ReviewsComponent } from './components/reviews/reviews.component';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    ProductsComponent,
    ProductComponent,
    ContactComponent,
    AboutComponent,
    CheckoutComponent,
    ReviewsComponent
  ],
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    BrowserModule.withServerTransition({ appId: 'app-root' }),
    CarouselModule,
    CommonModule,
    NgtUniversalModule,
    TransferHttpCacheModule,
    TransferHttpModule,
    HttpClientModule,
    FormsModule,
    BrowserModule,
    NgxJsonLdModule,
    BrowserAnimationsModule,
    AppRoutingModule,
  ],
  providers: [
    ScriptService,
    PetService,
    CartService,
    ApiService,
    SEOService,
    TransferHttpService
  ],
})
export class AppModule { }
