import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './not-found/not-found.component';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { ProductComponent } from './components/product/product.component';
import { ProductsComponent } from './components/products/products.component';
import { ReviewsComponent } from './components/reviews/reviews.component';

const routes: Routes = [
  { path: '', component: HomeComponent, data: {title: 'Welcome Home'}},
  { path: 'about', component: AboutComponent, data: {title: 'About'}},
  { path: 'contact', component: ContactComponent, data: {title: 'Contact'}},
  { path: 'checkout', component: CheckoutComponent, data: {title: 'Checkout'}},
  { path: 'pet/:id', component: ProductComponent},
  { path: 'pet', component: ProductComponent},
  { path: 'pets', component: ProductsComponent, data: {title: 'Available Pets'}},
  { path: 'testimonials', component: ReviewsComponent, data: {title: 'Testimonials'}},
  { path: '404', component: NotFoundComponent },
  // {path: '**', redirectTo: '/404', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
