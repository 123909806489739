import { Component, OnInit } from '@angular/core';
import { Pet } from '../../models/pets';
import { Contact } from '../../models/api/contact';
import { Seo } from '../../models/api/seo.model';
import { PetService } from '../../services/pets.service';
import { ApiService } from '../../services/api.service';
import { SEOService } from '../../services/seo.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  pets: Pet[] = new Array();
  contact: Contact = new Contact();
  isLoading = false;
  seo = new Seo()
  tags = new Array()
  date: Date
  properties = new Array()
  schema;
  navigationSchema;
  buttonMsg = "Send Message";
  private pathToAssets: string = "../../assets/";


  constructor(
    private productService: PetService, 
    private apiService: ApiService,
    private seoService: SEOService) {
      var now = new Date(), y = now.getFullYear(), m = now.getMonth();
      this.date = new Date(y, m + 1, 0);
      console.log(this.date.toUTCString())
  }

  ngOnInit() {
    this.pets = this.productService.findAll();
  }

  printPrice(price : number)
  {
    var price_parts = price.toString().split(".");
    price_parts[0] = price_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return price_parts.join(".");
  }

}
