import { Deserializable } from "./deserializable";

export class Pet implements Deserializable {
  id: string;
  name: string;
  gender: string;
  age: string;
  breed: string;
  description: string;
  image: string;
  price: number;
  adopted: boolean;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export const PETS: Pet[] = [

    {
        id: 'gizmo', name: 'GIZMO', image: 'assets/images/adoption/gizmo.jpg',
        gender: 'Male', age: "10 Weeks", price: 900,
        breed: "French Bulldog",
        adopted: false,
        description: "He is a bundle of joy to any loving and caring Friendly. Puppy is well socialized at this young age. They are living in our kitchen to ensure they are used to the day to day activities of a busy household. They are well mauled and played with daily. We often have visitors/friends come and play with the puppies as well. They adapt fairly quickly to new situations.",
    } as Pet,

    {
        id: 'luna', name: 'LUNA', image: 'assets/images/adoption/luna.jpg',
        gender: 'Female', age: "9 Weeks", price: 900,
         breed: "French Bulldog",
         adopted: true,
        description: "Luna is a wonderful loving little French Bulldog puppy, with a curious personality. She is a gorgeous female pup that wants to light up your life. Luna will have a complete nose to tail vet check and arrive with a current health certificate. She will love running around town with you doing errands or snuggling at home to relax. Luna is well socialized and eager to find her forever home.",
    } as Pet,

    {
        id: 'hugo', name: 'HUGO', image: 'assets/images/adoption/hugo.jpg',
        gender: 'Male', age: "10 Weeks", price: 900,
        breed: "French Bulldog",
        adopted: false,
        description: "Hugo is a very special baby boy! He may be a baby now, but isn’t lacking when it comes to personality! He’s super friendly and loves everyone he meets! His coat is thick and soft. This baby is going to make a great companion and be very loyal to his family. Hugo is always doing something sweet to catch your attention and it always works! He is very affectionate and loves cuddling.",
    } as Pet,

    {
        id: 'oscar', name: 'OSCAR', image: 'assets/images/adoption/oscar.jpg',
        gender: 'Male', age: "10 Weeks", price: 900,
        breed: "French Bulldog",
        adopted: true,
        description: "Here comes Oscar, a beautiful French Bulldog puppy ready to put a smile on your face! This angelic pup is vet checked and up to date on shots and wormer. Oscar is registered with the AKC and comes with a 1-year health guarantee provided by us. This bouncy pup is family raised with children and would make an excellent addition to anyone’s family.",
    } as Pet,

    {
        id: 'ollie', name: 'OLLIE', image: 'assets/images/adoption/ollie.jpg',
        gender: 'Female', age: "10 Weeks", price: 900,
        breed: "French Bulldog",
        adopted: false,
        description: "Charming Frenchie Ollie now ready to meet their new loving Friendly. She is current on all shots, potty trained, and has an excellent pedigree. Will come with toys. We are looking for the perfect homes for her where she will be shown all love and care. Parents are both health tested. We expect nothing less from these little ones.",
    } as Pet,

    {
        id: 'louie', name: 'LOUIE', image: 'assets/images/adoption/louie.jpg',
        gender: 'Female', age: "10 Weeks", price: 900,
        breed: "French Bulldog",
        adopted: false,
        description: "Louie is a precious little French Bulldog puppy who is just as cute as can be! This social gal is vet checked and up to date on shots and wormer. She is registered with the AKC and Potty Trained, plus comes with a health guarantee provided by the breeder. Louie is family raised with children and she loves to cuddle and play.",
    } as Pet,

    {
        id: 'enzo', name: 'ENZO', image: 'assets/images/adoption/enzo.jpg',
        gender: 'Male', age: "10 Weeks", price: 900,
        breed: "French Bulldog",
        adopted: true,
        description: "Check out, Enzo! He is an adorable French Bulldog puppy with a bubbly spirit. This precious little guy loves to play and is ready to join in all of your family fun. Enzo is vet checked and up to date on shots and wormer. He is registered with the AKC, plus comes with a health guarantee provided by us.",
    } as Pet,

    {
        id: 'daisy', name: 'DAISY', image: 'assets/images/adoption/daisy.jpg',
        gender: 'Female', age: "9 Weeks", price: 900,
        breed: "French Bulldog",
        adopted: false,
        description: "This sweet little frenchie is Daisy, a family raised French Bulldog puppy! She has the most adorable scrunchy face and she can’t wait to give you lots of love and puppy kisses. Daisy is vet checked, up to date on shots and dewormer, plus we provide a 1 Year genetic health guarantee. This cutie is registered with the AKC and both parents live on the premises.",
    } as Pet,


    {
        id: 'frankie', name: 'FRANKIE', image: 'assets/images/adoption/frankie.jpg',
        gender: 'Male', age: "9 Weeks", price: 900,
        breed: "French Bulldog",
        adopted: false,
        description: "Frankie is a precious little French Bulldog puppy who is just as cute as can be! This social little guy is vet checked and up to date on shots and wormer. He is registered with the AKC and Potty Trained, plus comes with a health guarantee provided by the breeder. Frankie is family raised with children and he loves to cuddle and play.",
    } as Pet,

    {
        id: 'olive', name: 'OLIVE', image: 'assets/images/adoption/olive.jpeg',
        gender: 'Female', age: "9 Weeks", price: 900,
        breed: "French Bulldog",
        adopted: true,
        description: "This sweet little frenchie is Olive, a family raised French Bulldog puppy! She has the most adorable scrunchy face and she can’t wait to give you lots of love and puppy kisses. Olive is vet checked, up to date on shots and dewormer, plus we provide a 1Year genetic health guarantee. This cutie is registered with the AKC and both parents live on the premises.",
    } as Pet,

    {
        id: 'sophie', name: 'SOPHIE', image: 'assets/images/adoption/sophie.jpg',
        gender: 'Female', age: "9 Weeks", price: 900,
        breed: "French Bulldog",
        adopted: false,
        description: "This sweet little frenchie is Sophie, a family raised French Bulldog puppy! She has the most adorable scrunchy face and she can’t wait to give you lots of love and puppy kisses. Sophie is vet checked, up to date on shots and dewormer, plus we provide a 1Year genetic health guarantee. This cutie is registered with the AKC and both parents live on the premises.",
    } as Pet,

    {
        id: 'diesel', name: 'DIESEL', image: 'assets/images/adoption/diesel.jpeg',
        gender: 'Male', age: "9 Weeks", price: 900,
        breed: "French Bulldog",
        adopted: true,
        description: "Here comes Diesel, a beautiful French Bulldog puppy ready to put a smile on your face! This angelic pup is vet checked and up to date on shots and wormer. Diesel is registered with the AKC and comes with a 1-year health guarantee provided by us. This bouncy pup is family raised with children and would make an excellent addition to anyone’s family.",
    } as Pet,

    {
        id: 'jerry', name: 'JERRY', image: 'assets/images/adoption/jerry.jpeg',
        gender: 'Male', age: "9 Weeks", price: 900,
        breed: "French Bulldog",
        adopted: false,
        description: "This sweet little frenchie is Jerry, a family raised French Bulldog puppy! He has the most adorable scrunchy face and he can’t wait to give you lots of love and puppy kisses. Jerry is vet checked, up to date on shots and dewormer, plus we provide a 1Year genetic health guarantee. This cutie is registered with the AKC and both parents live on the premises.",
    } as Pet,

    {
        id: 'gucci', name: 'GUCCI', image: 'assets/images/adoption/gucci.jpg',
        gender: 'Male', age: "9 Weeks", price: 900,
        breed: "French Bulldog",
        adopted: true,
        description: "Check out, Gucci! He is an adorable French Bulldog puppy with a bubbly spirit. This precious little guy loves to play and is ready to join in all of your family fun. Gucci is vet checked and up to date on shots and wormer. He is registered with the AKC, plus comes with a health guarantee provided by us.",
    } as Pet,


    {
        id: 'bonnie', name: 'BONNIE', image: 'assets/images/adoption/bonnie.jpg',
        gender: 'Female', age: "9 Weeks", price: 900,
        breed: "French Bulldog",
        adopted: false,
        description: "This sweet little frenchie is Bonnie, a family raised French Bulldog puppy! She has the most adorable scrunchy face and she can’t wait to give you lots of love and puppy kisses. Bonnie is vet checked, up to date on shots and dewormer, plus we provide a 1Year genetic health guarantee. This cutie is registered with the AKC and both parents live on the premises.",
    } as Pet,

    {
        id: 'billy', name: 'BILLY', image: 'assets/images/adoption/billy.jpg',
        gender: 'Male', age: "9 Weeks", price: 900,
        breed: "French Bulldog",
        adopted: false,
        description: "Here comes Billy, a beautiful French Bulldog puppy ready to put a smile on your face! This angelic pup is vet checked and up to date on shots and wormer. Billy is registered with the AKC and comes with a 1-year health guarantee provided by us. This bouncy pup is family raised with children and would make an excellent addition to anyone’s family.",
    } as Pet,

    {
        id: 'cj', name: 'CJ', image: 'assets/images/adoption/cj.jpg',
        gender: 'Male', age: "9 Weeks", price: 900,
        breed: "French Bulldog",
        adopted: false,
        description: "This sweet little frenchie is CJ, a family raised French Bulldog puppy! He has the most adorable scrunchy face and he can’t wait to give you lots of love and puppy kisses. CJ is vet checked, up to date on shots and dewormer, plus we provide a 1 Year genetic health guarantee. This cutie is registered with the AKC and both parents live on the premises.",
    } as Pet,

    {
        id: 'dexter', name: 'DEXTER', image: 'assets/images/adoption/dexter.jpg',
        gender: 'Male', age: "9 Weeks", price: 900,
        breed: "French Bulldog",
        adopted: false,
        description: "Check out, Dexter! He is an adorable French Bulldog puppy with a bubbly spirit. This precious little guy loves to play and is ready to join in all of your family fun. Dexter is vet checked and up to date on shots and wormer. He is registered with the AKC, plus comes with a health guarantee provided by us.",
    } as Pet,
];
  